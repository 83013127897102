import React from "react"
import Layout from "../shared/Layout/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import { ImageBlock } from "../shared/Block/block"
import { ButtonLight, TextButton } from "../shared/Button/button"
import WorkplaceImage from "../images/workplace-1.jpeg"
import CellphoneImage1 from "../images/cellphone.jpeg"
import CodingImage from "../images/coding.jpeg"
import PackageImage1 from "../images/package.jpeg"

/*
"no-repeat linear-gradient(
          135deg,
          var(--color-invalid) 0%,
          rgb(40, 60, 140) 25%,
          var(--color-invalid) 50%,
          var(--color-shock-2) 85%)"
*/

const P123 = styled.div`
  margin: 0 0 var(--default-margin) 0;
  padding: 0 0 0 0;

  font-size: 1.2rem;
  line-height: 2rem;
  font-family: nunito sans;

  text-shadow: 0px 0px 8px rgb(0, 0, 0);
`

const P = ({ children }) => (
  <div
    style={{
      margin: `0 0 var(--default-margin) 0`,
      padding: `0 0 0 0`,
      //
      fontSize: `1.2rem`,
      lineHeight: `2rem`,
      fontFamily: `nunito sans`,
      //
      textShadow: `0px 0px 8px rgb(0, 0, 0)`,
    }}
  >
    {children}
  </div>
)

const H1 = styled.h1`
  text-shadow: 0px 0px 4px rgb(0, 0, 0.95);
  margin: ${props => props.margin};
  text-align: ${props => props.text_align};
`
const H2 = styled.h2`
  text-shadow: 0px 0px 4px rgb(0, 0, 0.95);
`
const H3 = styled.h3`
  text-shadow: 0px 0px 4px rgb(0, 0, 0.95);
`

const IndexPage = () => (
  <>
    <Layout>
      <SEO title="Team Immonen" />
      <ImageBlock
        image={WorkplaceImage}
        cover_or_contain="cover"
        background_position="center"
        image_gradient="no-repeat radial-gradient(
          circle at bottom,
          rgb(0, 0, 0, 0.7) 30%,
          transparent 80%
        )"
        min_height="45rem"
      >
        <H1
          margin="8rem 0 4rem 0" //margin="8rem 0 4rem 14rem"
          style={{ alignSelf: `flex-end` }}
        >
          Uudet sivut yrityksellesi
        </H1>
        <P>Tilaa modernit ja pätevän näköiset sivut ripeällä aikataululla.</P>
        <P>
          <TextButton to="/services" key="Services">
            <H3 style={{ margin: `0` }}>Palvelut {"→"}</H3>
          </TextButton>
        </P>
      </ImageBlock>

      <ImageBlock
        background_gradient="no-repeat radial-gradient(circle at center, var(--color-primary-05) 0%, var(--color-primary-01) 99%)"
        min_height="25rem"
        min_mobile_height="32rem"
        preferred_max_width="35rem"
      >
        <H2 style={{ margin: `0`, lineHeight: `2.25rem` }}>
          Paranna yrityksesi{" "}
          <span style={{ color: `var(--color-shock-3)` }}>näkyvyyttä</span> niin
          uusille kuin vanhoille asiakkaillesi hankkimalla uudet sivut Team
          Immoselta.{" "}
        </H2>
      </ImageBlock>

      <ImageBlock
        image={CellphoneImage1}
        cover_or_contain="cover"
        background_position="center"
        image_gradient="no-repeat radial-gradient(
          ellipse at top 65% left 35%,
          rgb(0, 0, 0, 0.65) 0%,
          rgb(0, 0, 0, 0.15) 100%
        )"
        min_height="35rem"
        min_mobile_height="50rem"
        preferred_max_width="40rem"
      >
        <H1 style={{ marginBottom: `4rem` }}>Mihin sivuja oikein tarvitaan?</H1>
        <P>
          Vuonna {new Date().getFullYear()} yrityksesi sivut toimivat modernina
          käyntikorttinasi. Optimoitujen sivujen ansiosta palvelusi tulevat
          hakukoneissa näkyviksi niin uusille kuin vanhoillekin asiakkaillesi.
        </P>
        <P>
          Sivut myös tuovat{" "}
          <span style={{ color: `var(--color-shock-3)` }}>uskottavuutta</span>{" "}
          toiminnallesi.
        </P>
      </ImageBlock>

      <ImageBlock
        image={PackageImage1}
        cover_or_contain="contain"
        background_position="left"
        background_gradient="no-repeat linear-gradient(
          0deg,
          var(--color-primary-02) 0%,
          var(--color-primary-04) 60%
        )"
        min_height="35rem"
        min_mobile_height="50rem"
        preferred_width="45vw"
        preferred_max_width="60rem"
        flex_dir="flex-end"
      >
        <H2>Valmis paketti...</H2>
        <P>Haluatko säästyä tekniikan kanssa tappelemiselta?</P>
        <P>
          Valmis paketti tarkoittaa sivuja, joista sinulle jää mietittäväksi
          sivujen sisältö ilman, että joutuisit miettimään toteuttamisen
          teknistä puolta.
        </P>
        <P>
          <ButtonLight to="/services" key="Services">
            Palvelut
          </ButtonLight>
        </P>
      </ImageBlock>

      <ImageBlock
        image={CodingImage}
        background_gradient="no-repeat radial-gradient(
          circle at center,
          var(--color-primary-05) 0%,
          var(--color-primary-02) 99%
        )"
        // image_gradient="no-repeat linear-gradient(
        //   90deg,
        //   transparent 40%,
        //   rgb(0, 0, 0, 0.2) 100%
        // )"
        cover_or_contain="auto 100%"
        background_position="right top"
        // preferred_parent_width="60vw"
        preferred_width="60rem"
        preferred_max_width="60rem"
        flex_dir="flex-start"
        min_height="40rem"
      // text_width="50vw"
      // text_width="75rem"
      >
        <H2>...vai teknistä apua</H2>
        <P>
          Tekninen konsultointi tarkoittaa apua sivujen pystytyksessä ja
          sivuihin liittyvän teknisen puolen kanssa. Voit itse vastata sivuston
          sisällön päivityksestä, mutta hoidan puolestasi teknisten haasteiden
          ratkomisen.
        </P>
        <P>Löydät tarkemman kuvauksen palveluista:</P>

        <P>
          <ButtonLight to="/services" key="Services">
            Palvelut
          </ButtonLight>
        </P>
      </ImageBlock>
    </Layout>
  </>
)

export default IndexPage
